<template>
  <v-navigation-drawer
    v-model="show"
    app
    :mini-variant.sync="mini"
    permanent
    :disable-route-watcher="true"
    :disable-resize-watcher="true"
    @transitionend="transitionend"
    @update:mini-variant="onUpdate"
  >
    <template v-slot:append>
      <v-list-item>
        <v-btn icon @click.stop="mini = !mini">
          <v-icon>{{ mini ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
        </v-btn>
      </v-list-item>
      <div class="text-center text-caption">V. 0.2.2</div>
    </template>
      <v-list-item class="px-2" two-line>
        <v-list-item-avatar @click.stop="mini = !mini" tile>
          <v-img src="/img/dtd-128.png"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ user || 'LEA'}}</v-list-item-title>
          <v-list-item-subtitle v-if="user" class="text-caption text-decoration-underline cursor-pointer" @click="onLogout">Logout</v-list-item-subtitle>
          <v-list-item-subtitle v-if="!user" class="text-caption text-decoration-underline cursor-pointer" @click="onLogin">Login</v-list-item-subtitle>
        </v-list-item-content>
        <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>        
      </v-list-item>
    <v-divider></v-divider>
    <v-list dense nav>
		  <template v-for="([group, icon, text, href], index) in licensedLinks">
        <v-list-group
          v-if="group && group.startsWith('*')"
          :key="index"
          no-action
          :prepend-icon="icon"
          :title="text"
          @click="toggleGruppo"
        >
          <template v-slot:activator>
            <v-list-item-content>            
              <v-list-item-title>{{text}}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="([, icon, text, href], index) in licensedLinksGroup(group)"
            :to="href"
            :key="index"
            :title="text"
            link
          >
            <v-list-item-icon v-if="icon" class="icon-group">
              <v-icon :title="text">{{ icon }}</v-icon>
            </v-list-item-icon>     
            <v-list-item-content>
              <v-list-item-title>{{ text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item
          v-else
          :to="href"
          :key="text"
          :title="text"
          link
        >
          <v-list-item-icon>
            <v-icon :title="text">{{ icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
		</v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data: () => ({
    links: [
      ['', 'mdi-home', 'Home', '/'],
      ['', 'mdi-home-city-outline', 'Enti asseverabili', '/v-enti-asseverabili'],
      ['*Tabelle', 'mdi-table', 'Tabelle', '', 'Tabelle'],
      ['Tabelle', 'mdi-table', 'Asseverazioni', '/v-tabelle/asseverazioni', 'Asseverazioni'],
      ['Tabelle', 'mdi-table', 'Task', '/v-tabelle/task', 'Task'],
      ['Tabelle', 'mdi-table', 'Enti al Target', '/v-tabelle/target', 'Enti'],
      ['', 'mdi-chart-bar-stacked', 'Grafici', '/v-grafici', 'Grafici'],
      ['', 'mdi-cog-outline', 'Impostazioni', '/v-impostazioni', 'Admin']
    ],
    show: true,
    mini: true
  }),
  watch: {
    showDrawer(value) {
      if (value) {
        this.show = true
      }
    }
  },
  computed: {
    showDrawer() {
      return this.$store.getters.leftDrawer
    },
    user() {
      console.log('change utente')
      return this.$store.getters['utenti/user']
    },
    trueLinks() {
      return this.links.filter(x => !x[0] || x[0].startsWith('*')) // ritorno solo se non hanno gruppo o la testata del gruppo
    },
    licensedLinks() {
      const userInfo = this.$store.getters['utenti/info']
      if (!userInfo) {
        return []
      }
      const userIsAdmin = userInfo.isAdministrator
      const ruoli = userInfo.ruoli || []
      const links = this.links.filter(x => { 
        return (!x[4] || ruoli.includes(x[4])) && (!x[5] || userIsAdmin)
      })
      return links.filter(x => !x[0] || x[0].startsWith('*')) // ritorno solo se non hanno gruppo o la testata del gruppo
    }    
  },
  methods: {
    toggleGruppo() { // Se seleziono un gruppo, apro il drawer per far vedere il contenuto
      this.mini = false
    },    
    trueLinksGroup(group) {
      const gp = group.slice(1)
      return this.links.filter(x => x[0] === gp)
    },
    licensedLinksGroup(group) {
      const gp = group.slice(1)
      const userInfo = this.$store.getters['utenti/info']
      if (!userInfo) {
        return []
      }      
      const userIsAdmin = userInfo.isAdministrator
      const ruoli = userInfo.ruoli || []
      const licensedLinks = this.links.filter(x => (!x[4] || ruoli.includes(x[4])) && (!x[5] || userIsAdmin))
      return licensedLinks.filter(x => x[0] === gp)
    },    
    onLogin() {
      this.$router.push('/login')
    },    
    onLogout() {
      this.$store.commit('utenti/logout')
      console.log('route name:', this.$route.name)
      if (this.$route.name !== 'HomeAuth') {
        this.$router.replace('/')
      }
      this.mini = false
    },    
    transitionend() {
      this.$store.commit('SET_LEFT_DRAWER', false)
    },
    onUpdate(event) {
      // Lascia chiuso il drawer
      this.mini = !this.mini
    }
  }
}
</script>

<style lang="scss" scoped>
  .cursor-pointer {
    cursor: pointer;
  }
</style>
