var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"app":"","mini-variant":_vm.mini,"permanent":"","disable-route-watcher":true,"disable-resize-watcher":true},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":[function($event){_vm.mini=$event},_vm.onUpdate],"transitionend":_vm.transitionend},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-list-item',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.mini = !_vm.mini}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mini ? 'mdi-chevron-right' : 'mdi-chevron-left'))])],1)],1),_c('div',{staticClass:"text-center text-caption"},[_vm._v("V. 0.2.2")])]},proxy:true}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-list-item',{staticClass:"px-2",attrs:{"two-line":""}},[_c('v-list-item-avatar',{attrs:{"tile":""},on:{"click":function($event){$event.stopPropagation();_vm.mini = !_vm.mini}}},[_c('v-img',{attrs:{"src":"/img/dtd-128.png"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.user || 'LEA'))]),(_vm.user)?_c('v-list-item-subtitle',{staticClass:"text-caption text-decoration-underline cursor-pointer",on:{"click":_vm.onLogout}},[_vm._v("Logout")]):_vm._e(),(!_vm.user)?_c('v-list-item-subtitle',{staticClass:"text-caption text-decoration-underline cursor-pointer",on:{"click":_vm.onLogin}},[_vm._v("Login")]):_vm._e()],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.mini = !_vm.mini}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1)],1),_c('v-divider'),_c('v-list',{attrs:{"dense":"","nav":""}},[_vm._l((_vm.licensedLinks),function(ref,index){
var group = ref[0];
var icon = ref[1];
var text = ref[2];
var href = ref[3];
return [(group && group.startsWith('*'))?_c('v-list-group',{key:index,attrs:{"no-action":"","prepend-icon":icon,"title":text},on:{"click":_vm.toggleGruppo},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(text))])],1)]},proxy:true}],null,true)},_vm._l((_vm.licensedLinksGroup(group)),function(ref,index){
var icon = ref[1];
var text = ref[2];
var href = ref[3];
return _c('v-list-item',{key:index,attrs:{"to":href,"title":text,"link":""}},[(icon)?_c('v-list-item-icon',{staticClass:"icon-group"},[_c('v-icon',{attrs:{"title":text}},[_vm._v(_vm._s(icon))])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(text))])],1)],1)}),1):_c('v-list-item',{key:text,attrs:{"to":href,"title":text,"link":""}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"title":text}},[_vm._v(_vm._s(icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(text))])],1)],1)]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }