<template>
<v-app>
  <left-drawer/>
  <v-app-bar dense app color="#0066cc">
    <v-app-bar-title class="white--text">LEA - Lista enti asseverabili</v-app-bar-title>
    <v-spacer></v-spacer>
  </v-app-bar>
  <v-main>
    <v-container fluid>
      <router-view></router-view>
    </v-container>
  </v-main>

  <v-footer app>
    <!-- -->
  </v-footer>
</v-app>
</template>

<script>
  import LeftDrawer from './views/drawer/leftDrawerMini'

export default {
  name: 'App',
  components: {
    LeftDrawer
  },
  data: () => ({
    usaLeft2: false
  }),
  methods: {
    openLeftDrawer() {
      this.$store.commit('SET_LEFT_DRAWER', true)
    }
  }
};
</script>
