import Vue from 'vue'
import Vuex from 'vuex'
import selfapi from '@/services/selfapi'

var jwt = require('jsonwebtoken')

Vue.use(Vuex)

const isValidToken = (auth) => {
  return auth && auth.expiryDate > new Date().getTime();
}

const refreshToken = async (auth) => {
  if (!auth) return null
  let newAuth = await selfapi.refreshToken(auth.refreshToken)
  if (newAuth && newAuth.token) {
    auth = { ...auth, ...newAuth}
    localStorage.setItem('info', JSON.stringify(auth))
    return newAuth
  }
  return null
}

const verificaToken = async (auth) => {
  let authValido = auth
  let tokenValido = isValidToken(auth)
  if (!tokenValido) {
    authValido = await refreshToken(auth)
  }
  return authValido && authValido.token
}

const verificaLogin = async (auth) => {
  let tokenValido = isValidToken(auth)
  if (!tokenValido) {
    const refreshed = await refreshToken(auth)
    console.log('refreshed', refreshed)
    if (refreshed) {
      return true
    }
  }
  return auth && tokenValido
}

const initialState = (tipo) => {
  const ins = JSON.parse(localStorage.getItem('info'))
  switch(tipo) {
    case 'user':
      return ins ? ins.user : null
    case 'token':
      return ins ? ins.token : null
    default:
      return ins ? ins : null
  }
  
}

export default {
  namespaced: true,
  state: {
    info: initialState(),
    user: initialState('user'),
    token: initialState('token')
  },
  getters: {
    isLoggedIn(state) {
      return !!initialState('token') // !!state.token
    },
    info: state => {
      const info = initialState() // state.info
      if (info) {
        let decodedToken = jwt.decode(info.token)
        return { ...info, ...decodedToken }
      } else {
        return null
      }
    },
    user: state => initialState('user'), // state.user,
    isAdministrator: state => state.info.ruoli && state.info.ruoli.includes('Admin'),
    token: async (state) => {
      const info = initialState() // state.info
      return await verificaToken(info)
    }
  },
  mutations: {
    setInfo(state, info) {
      state.info = info
      state.token = info.token
      state.user = state.info.user
      localStorage.setItem('info', JSON.stringify(info))
    },
    setToken(state, token) {
      state.token = token
    },
    logout(state) {
      state.info = null
      state.token = null
      state.user = null
      localStorage.removeItem('info')
    }
  },
  actions: {
    async setInfo({ commit, getters, dispatch }, info) {
      commit('setInfo', info)
    },
    async verifyAndGetUser({getters}) {
      let info = initialState()
      if (info) {
        let decodedToken = jwt.decode(info.token)
        info = { ...info, ...decodedToken }
      } else {
        info = null
      }
      let valido = await verificaLogin(info)
      return valido ? info : null
    }
  }
}
