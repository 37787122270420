import Vue from 'vue'
import Vuex from 'vuex'

/* import clienti from './clienti'
import services from './services' */
import utenti from './utenti'
// import mobile from './mobile'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
/*     clienti,
    services, */
    utenti
    // mobile
  },
  state: {
    leftDrawer: false,
/*     rightDrawer: false,
    hideRightDrawer: false,
    rightDrawerReadOnly: false, */
    token: ''
  },
  getters: {
    leftDrawer: state => state.leftDrawer,
/*     rightDrawer: state => state.rightDrawer,
    hideRightDrawer: state => state.hideRightDrawer,
    rightDrawerReadOnly: state => state.rightDrawerReadOnly, */
    token: state => state.token
  },
  mutations: {
    SET_LEFT_DRAWER(state, value) {
      state.leftDrawer = value
    },
/*     SET_RIGHT_DRAWER(state, value) {
      state.rightDrawer = value
    },
    HIDE_RIGHT_DRAWER(state, value) {
      state.hideRightDrawer = value
    },
    SET_RIGHT_DRAWER_READ_ONLY(state, value) {
      state.rightDrawerReadOnly = value
    }, */
    SET_TOKEN(state, value) {
      state.token = value
    }
  }
})
